import React from 'react';
import { Link } from 'gatsby';
import { Container, Button } from 'react-bootstrap';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <SEO title="404: Not found" />
      <h1>404: Pagina niet gevonden</h1>
      <p>We kunnen de pagina die je zoekt niet vinden.</p>
      <Link to="/">
        <Button variant="success">Naar de homepagina</Button>
      </Link>
    </Container>
  </Layout>
);

export default NotFoundPage;
